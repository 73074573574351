import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import GeneralLayout from '../components/GeneralLayout';
import CategoryMenu from '../components/CategoryMenu';

export default function news({ pageContext }) {
  const listQuery = useStaticQuery(graphql`
    query {
      allSanityMedia(sort: {fields: date, order: DESC}) {
        nodes {
          date
          externalUrl
          featured
          id
          mainImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          mediaCategory {
            title
          }
          otherImages {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
          slug {
            current
          }
          title
        }
      }
    }
  `);

  const {
    date, mainImage, otherImages, title, writingBodyText, description
  } = pageContext;
  const activeCategory = title;

  function styleText(paragraph) {
    return (
      <p className={paragraph.children[0].marks.length > 0 ? 'media-text strong' : 'media-text'}>{paragraph.children[0].text}</p>
    );
  }

  useEffect(() => {
    document.getElementsByTagName('html')[0].style.overflowY = 'auto';
    document.getElementsByTagName('body')[0].style.overflowY = 'auto';
    document.getElementById('___gatsby').style.overflowY = 'auto';
    document.getElementById('gatsby-focus-wrapper').style.overflowY = 'auto';
  }, []);

  return (
    <GeneralLayout>
      <div className="info">
        <div className="result">
          <div className="result-content media-page">
            <div className="result-header">
              <p className="media">{date.substring(0, 4)}</p>
              <h3 className="media">{title}</h3>
            </div>
          </div>
        </div>
        <div className="media-content">
          <div className="images">
            <GatsbyImage className="project-image media-page" image={mainImage} alt={title} />
            {otherImages.map((otherImage) => (
              <GatsbyImage className="project-image media-page" image={otherImage} alt={title} />
            ))}
          </div>
          <div className="text">
            {description ? description.map((paragraph) => styleText(paragraph)) : null}
            {writingBodyText ? writingBodyText.map((paragraph) => styleText(paragraph)) : null}
          </div>
        </div>
      </div>
      <CategoryMenu
        categoryType="Media"
        activeCategory={activeCategory}
        listQuery={listQuery}
      />
    </GeneralLayout>
  );
}
